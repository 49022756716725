/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "celebrities-with-hearing-aids-in-2022",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#celebrities-with-hearing-aids-in-2022",
    "aria-label": "celebrities with hearing aids in 2022 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Celebrities with Hearing Aids in 2022"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Approximately 37.5 million Americans, or about 15% of the population have reported some trouble with hearing, according to the National Institute on Deafness and Other Communication Disorders. With this many individuals affected, it’s no wonder that some of your favorite celebrities are among those who’ve experienced hearing loss. Here, we’ll discuss some notable individuals who have been open about their experience with hearing loss, and how a hearing aid could help you if you identify with them."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "celebrities-with-hearing-issues",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#celebrities-with-hearing-issues",
    "aria-label": "celebrities with hearing issues permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Celebrities with Hearing Issues"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many famous men and women in movies, sports, music, and other industries suffer from hearing problems. Some of the most well-known you might recognize are:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Marlee Matlin"), " – A severe illness caused total deafness in her right ear and only 20% of hearing in the other when she was 18 months old. However, with the help of lip-reading, sign language, and hearing aids, she managed to start and maintain an impressive acting career."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Bill Clinton"), " – At 51 years old, the 42nd president of the United States sought treatment for his hearing loss. He wears an in-ear hearing aid in each ear. He also uses his experience to promote hearing health in developing countries like Africa."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Derrick Coleman"), " – The successful NFL player lost his hearing at three years old due to a genetic abnormality. That didn't stop him from pursuing his dreams of becoming a professional football player."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "William Shatner"), " – The actor most known for his role on Star Trek suffers from tinnitus. Tinnitus is a hearing-related condition that causes ringing in one or both ears. Since his diagnosis, Shatner has advocated for research to discover a cure."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Pete Townshend"), " – The Who songwriter and guitarist doesn’t let his hearing loss prevent him from performing. Despite his impairment and tinnitus diagnosis, he continues to write songs and perform his music for his fans."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearing-aids-help-people-with-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearing-aids-help-people-with-hearing-loss",
    "aria-label": "how hearing aids help people with hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How Hearing Aids Help People with Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A hearing aid is an electronic device worn behind or in the ear. It amplifies some sounds so the wearer can communicate, listen, and participate in daily activities more easily. The device can also improve a person's hearing abilities in noisy and quiet environments."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "new-hearing-aid-technology-and-invisibility",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#new-hearing-aid-technology-and-invisibility",
    "aria-label": "new hearing aid technology and invisibility permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "New Hearing Aid Technology and Invisibility"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A hearing aid is not a one-size-fits-all device. You must find the right one to meet your needs. Some people have different hearing loss levels in each ear, so they require different hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With new technology, you can hide your hearing loss using almost invisible devices. Some sit deep in the ear canal so no one can see you’re wearing them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Digital hearing aids allow the wearer to utilize more features suited to their unique prescription. For example, you can use a device that amplifies high-frequency sounds if you have high-frequency hearing loss."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "contact-hearcom-for-further-information",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-hearcom-for-further-information",
    "aria-label": "contact hearcom for further information permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact Hear.com for Further Information"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Find out if you qualify for a no-risk trial with hear.com by completing this ", React.createElement(_components.a, {
    href: "https://www.hear.com/d/full_page_questionnaire/",
    className: "c-md-a"
  }, "questionnaire"), ". Let us help you find a solution to your hearing problems."), "\n", React.createElement(LandingPageCta, {
    copy: "Get Assistance from a Hearing Specialist",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
